import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`As three large, buffed up guards the size of club bouncers knocked on the door of the tiny, damp, dark cell, he stood up to pick up a chicken and rice, pancakes and orange juice from Mcdonalds. Esteban had just received the holy grail of prison cuisine, a meal that he hadn’t had in a while, and most hoped would never have again. Along with the guards came a priest, a short, older, gentleman with thin, gray hair which just barely covered his head. He was there to talk to Esteban and hopefully get him through the evening without him breaking down too many times. What the guards didn’t know, however, was that the six hours of preparation of the electric chair was completely and utterly useless. He was ready.`}</p>
    <ul>
      <li parentName="ul"></li>
    </ul>
    <p>{`The federal prison in a poor district on the outskirts of La Paz, in the eyes of Esteban, had many flaws in its infrastructure. So the first thing he did when he got in was a thorough examination of his cell and the mental mapping of the facilities to minimize the amount of time he would have to stay for. It all seemed natural at first, he would just dig a tunnel - like many others have done before, or bust out the cell door which already barely opened because of how broken it was. The options seemed to be almost limitless, but at first, he would have to wait a bit as not to arouse any initial suspicion in the guards and other Bolivian inmates.`}</p>
    <p>{`The next weeks were spent by carefully studying every weak point of the establishment. Every hole, tear, imperfection in his own tiny, damp, dark cell which he could take advantage of later on. The simplicity of this operation so far would surprise most if they knew about it.`}</p>
    <p>{`Four days later, two of the guards accompanied by what looked to be like their commander raided the cell of Esteban as they were suspicious of his recent activities despite the extra efforts to conceal his actions and expressions. They found nothing. It didn’t satisfy them enough, however, so they put Esteban in another holding cell to which they came every morning with a firehose to greet him with a complimentary shower. The catch soon became more apparent as he had been cut, strangled and drowned in another room on the other side of the campus, far from those who could listen to the faint screams of pain and suffering.`}</p>
    <p>{`He had been sentenced to death by electric chair on behalf of the brutal murder and dismemberment of Juan Rodriguez in the Bolivian city of Cochabamba, about 400km from the country’s capital. His plans to escape the prison and his strong desire to be free got overruled by the ill-treatment of the confinement employees and his current mental state now therefore rapidly deteriorating each day. He had become weak, both physically and mentally, laying on his bunk instead of scanning his surroundings and making plans to break out.`}</p>
    <p>{`A small, metal, barred window up in the corner of the back wall in Esteban’s cell soon became clear to be the most efficient, if not the only way to get out of death row. He had been tortured, harassed, mistreated, and he almost couldn’t get up on his feet, and there was no way he could harvest some power to break out the traditional way. The lack of energy in him, however, did not stop his determination from rising to the surface and carrying out a new, easier, and improvised plan. The facilities’ structure was in pretty bad shape. After all, he was in a quite underdeveloped region compared to some western cities. It was quite old and weak, but still sturdy enough to keep people without any apparent intentions in. The concrete would be too difficult to break through, taking his physical status into account, but by weakening the structure of the barred metal window, he thought he might eventually be able to bust it out and climb through.`}</p>
    <p>{`Every day at noon, guards would visit his cell to give him a meal which usually consisted of some bread, water, and traditional Bolivian Sopa de Mani. With enough experience in metalworking to work out how things rust, Esteban started assembling his master plan, which would one day finally set him free. Saving a small bit of the Bolivian soup every day and covering the metal joints on the corners of the small metal window, he was able to rust the connections away in about six months. By then, he was able to pull a few screws out and push the window outward, but he was sure to put it back each time so that the guards wouldn’t notice. His only fear at the moment would be that the guards could move him to a different cell without any rusted away windows to wait out his final days.`}</p>
    <p>{`Esteban let out an immense sigh of relief when three guards came in on his last day telling him that su Culo Patético would stay here and that they’ll bring in a meal and a priest in preparation for tomorrow. He was now fully prepared to run tonight, and he was confident his plan would work. For the remainder of the day, he sat in angst on his bunk running everything in his head over and over and over again.`}</p>
    <p>{`As three large, buffed up guards the size of club bouncers knocked on the door of the tiny, damp, dark cell, he stood up to pick up a chicken and rice, pancakes and orange juice from Mcdonalds. Esteban had just received the holy grail of prison cuisine, a meal that he hadn’t had in a while, and most hoped would never have again. Along with the guards came a priest, a short, older, gentleman with thin, gray hair which just barely covered his head. He was there to talk to Esteban and hopefully get him through the evening without him breaking down too many times. What the guards didn’t know, however, was that the six hours of preparation of the electric chair was completely and utterly useless. He was ready.`}</p>
    <p>{`As the clock struck eleven o’clock in the evening, after the last check up on the prisoners walked by, Esteban gathered up a few floorboards which he slipped into his cover to make it look like he was still there. Busting out the worn-out window was merely a minor inconvenience which held him up for about a minute. He just barely fit through the hole in the wall where the window once was, and he struggled a little bit. When he finally got out, he was almost free. Up ahead of him was a trench which he had to crawl into and climb up, and an electric fence currently down as it had broken down a year beforehand and there simply wasn’t enough money to repair it. Climbing over it was no big deal either, as he had already done so the past two times he broke out of prison. Once he got over, he was a free man. A feeling of joy and excitement rushed through his body as he was finally free again. He almost wanted to scream, but his mind got the better of him and soon ordered him to run.`}</p>
    <p>{`Headed home, the only thing Esteban could think of at the moment is how he would see his wife again, and they would move far away to the other side of the country where they could start a new life and a new family.`}</p>
    <p>{`However, upon Esteban’s arrival at the family home in a district on the outskirts of La Paz, he was surprised to find the door unlocked and the shoes of another man next to her own in the entry hall. He ran upstairs to the main bedroom to see his wife in the bed with another man, both sleeping. His passion to finally be free that roared in him while he was incarcerated and which switched onto the craving of her had now turned into immense hatred towards his wife and the man lying next to her. A short trip to the kitchen and back upstairs satisfied his hatred almost enough for him to calm back down, but the only way was to get rid of them all. First, he walked to the man’s side of the bed, raised his knife, and then stabbed the man, piercing his skin and his lung. Then he did it again and again and again until the screams of his unfaithful wife washed out the cries of the other man who soon dropped dead in the middle of the bed which was now a pool of blood. He then proceeded to slowly creep towards his petrified wife curled up and screaming in the corner of the room, in tears of grave terror.`}</p>
    <p>{`What came next many witnesses later described were cries of help and screams louder than those of infants on aircraft, followed by an eerie silence which weighed upon the entire neighborhood and filled the streets of rural La Paz. For three minutes the now converted lunatic stood in the doorway of what was now a mostly blood-covered room, with a smile from ear to ear only describable as that of a psychopath. Soon enough, the soft sound of a police vehicle far off in the distance occupied the ear and the mind of the soon to be convicted killer, until flashes of blue and red lit up the white, blood-covered transparent curtain and its reflection on the ceiling and walls of the crime scene. The man who was once Esteban had now transferred to the other side of the room, a knife still in his hand and facing the door, a ghastly grin on his hideous face. The man stood there motionless as countless cops filled the room, guns raised, screaming something incomprehensible to Esteban whose mind was occupied with vast emptiness.`}</p>
    <p>{`After several warning shouts meant to throw some of the terror back at Esteban, the maniac who didn’t bother flinching stood still, now slowly raising the blood-covered knife into the air, hungry for more. The horrid atmosphere which filled the room almost inexplicable to those lucky enough not to have witnessed it glued the officers’ feet to the ground as they were stopped in their tracks not long after their initial entry. The cops, now scared straight all thought the same at one moment as it began to rain bullets upon bullets until their magazines were out, with Esteban swinging around in the air like a ragdoll before dropping dead to the ground.`}</p>
    <p>{`That night, the atrocious atmosphere which seemed to have consumed a part of the policemen’s souls and was burned into their minds was not much more merciful to their memories than Esteban was to his wife and her lover.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      